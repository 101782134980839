import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Student } from 'src/app/shared/models/users/student.class';

@Component({
  selector: 'enroll-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.css'],
})
export class StudentCardComponent implements OnInit {
  @Input()
  detailsActive: boolean = false;
  @Input()
  checkeable: boolean = false;
  @Input()
  draggable: boolean = false;

  @Output()
  toggleCourseState: EventEmitter<Student> = new EventEmitter();
  @Output()
  toggleEditUserForm: EventEmitter<Student> = new EventEmitter();
  @Output()
  toggleCourseAccepted: EventEmitter<Student> = new EventEmitter();
  @Output()
  togglePaymentAccepted: EventEmitter<Student> = new EventEmitter();
  @Output()
  toggleDocumentsAccepted: EventEmitter<Student> = new EventEmitter();

  @Input() selectCard: boolean = false;
  @Output() onSelectCard:EventEmitter<Partial<Student> & {selectedCard:boolean}> = new EventEmitter()

  _student: Student;

  @Output()
  checked: EventEmitter<Student> = new EventEmitter();
  @Output()
  unChecked: EventEmitter<Student> = new EventEmitter();

  private baseUrlProject: string = environment.baseUrl;
  showDetail: boolean = false;
  checkStatus: boolean = false;

  PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  constructor() {/** */}

  ngOnInit(): void {/** */}

  @Input()
  set student(student: Student) {
    this._student = student;
  }

  ///OUTPUTS
  toggleStudentDetail(): void {
    if (!this.detailsActive) return;
    this.showDetail = !this.showDetail;
  }

  toggleStudentState() {
    this.toggleCourseState.emit(this._student);
  }

  toggleEditUser() {
    this.toggleEditUserForm.emit(this._student);
  }

  toggleInCourseAccepted() {
    this.toggleCourseAccepted.emit(this._student);
  }

  toggleCourseDocumentsAccepted() {
    this.toggleDocumentsAccepted.emit(this._student);
  }

  toggleCoursePaymentAccepted() {
    this.togglePaymentAccepted.emit(this._student);
  }

  toggleStatus($event) {
    const status = $event.target.checked;
    if (status) this.checked.emit(this._student);
    if (!status) this.unChecked.emit(this._student);
  }

  openFile(url: string) {
    if (!url || url === '') return;
    window.open(this.baseUrlProject + url, '_blank');
  }

  getDinamicDocument(documentName: string): string {
    return (this._student?.UserDocuments && this._student?.UserDocuments?.length > 0) ? this.getCompleteUrlFile(this._student.UserDocuments[0][documentName]) : '';
  }
  getStudentEmail(): string {
    /** TODO: Review flow Celic */
    // return (this._student?.ContactInfos && this._student?.ContactInfos?.length > 0) ? this._student.ContactInfos[0].Email : '';
    return this._student?.CedocEmail;
  }
  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }


  onCheckUser(event:Event){
    const value = (event.target as HTMLInputElement).checked;
    this.onSelectCard.emit({
      ...this._student,
      selectedCard: value
    });
  }
}
