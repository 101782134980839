import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CoursesService } from 'src/app/services/http/courses.service';
import { ModalsUserService } from 'src/app/services/alerts/modals-user.service';
import { Course } from 'src/app/shared/models/courses/course.model';
import { CourseOffer } from 'src/app/shared/models/courses/courseOffer.model';
import { Student } from 'src/app/shared/models/users/student.class';
import { StudentsService } from 'src/app/services/http/students.service';
import { ExcelService } from 'src/app/services/files/excel.service';
import { CoursesOffersService } from 'src/app/services/http/courses-offers.service';
import { Router } from '@angular/router';
import { Subject } from 'src/app/shared/models/subjects/subject.model';
import { SubjectGroup } from 'src/app/shared/models/subjects/subjectGroup.model';
import { Observable, concat, forkJoin, of, throwError } from 'rxjs';
import { Category } from 'src/app/shared/models/courses/category.model';
import { catchError, map, switchMap, toArray } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { School } from 'src/app/shared/models/schools/school.model';
import { swalClose, swalConfirm, swalError, swalLoading, swalSuccess, swalUpdateLoading, swalWarning } from 'src/utils/alerts';


@Component({
  selector: 'enroll-students-courses-page',
  templateUrl: './manage-students-courses-page.component.html',
  styleUrls: ['./manage-students-courses-page.component.css'],
})
export class StudentsCoursesPageComponent implements OnInit {
  admin = false;
  @ViewChild('oapModal') private oapModal: SwalComponent;

  private isAuth = false;
  private mySchool = 25;

  ///////Offer & Course selectors MAIN (Origin)
  public selectedCourse: Course = null;
  public coursesList: Course[] = [];
  public coursesListFull: Course[] = [];

  public categoriesList: Category[] = [];
  public categorySelected: Category;
  public selectedOffer: CourseOffer = null;
  public offersList: CourseOffer[] = [];

  public selectedSubject: Subject = null;
  public subjectsList: Subject[] = [];

  /////// Offer & Group selectors
  idOriginGroupSelected: string = null;
  selectedOriginGroup: any;
  public originGroups = [];

  public originSubjecGroup: SubjectGroup[] = [];

  public idOfferDestinationSelected: string = null;
  public selectedDestinationOffer: CourseOffer;
  public destinationOffersList: CourseOffer[] = [];

  public idDestinationGroupSelected: number = null;
  public selectedDestinationGroup: any;
  public destinationGroups = [];

  public destinationSubjecGroup: SubjectGroup[] = [];

  //Control variables to view
  public changeStudentsSubject: boolean = false;
  public changeStudentsGroup: boolean = false;

  /////Main student list
  public studentsList: Array<Student> = [];

  public totalApproved: number = 0;
  public totalUnapproved: number = 0;

  studentsOriginListGroups: Array<Student> = [];
  studentsDestinationListGroups: Array<Student> = [];
  /////Secondary student list
  public secondaryStudentsList: Array<Student> = [];

  /////Excel file with OAP information
  public oapListBd = [];
  public oapList: { state: string, data: any[] } = {
    state: '',
    data: []
  };
  public oapTotalListBd: number = 0;
  public groupIdSelected: number;
  public validatedStudentsList: Array<Student> = [];
  public pendingStudentsList: Array<Student> = [];

  public SCHOOL_NAME: string = environment.SCHOOL_NAME;

  public allOffersList: CourseOffer[] = [];
  allSchoolsList: School[] = [];

  constructor(
    private router: Router,
    private modalsUserService: ModalsUserService,
    private excelService: ExcelService,
    private coursesService: CoursesService,
    private coursesOffersService: CoursesOffersService,
    private studentsService: StudentsService,
  ) {
    this.coursesService.getAllSchoolsWithAllData().subscribe((schoolsList) => {
      schoolsList.forEach(schoolIterated => {
        schoolIterated.Courses.forEach(courseIterated => {
          this.allOffersList.push(...courseIterated.CourseOfer);
        });
      });
    });
  }

  public ngOnInit(): void {
    this.getRoles();
  }

  private getRoles(): void {
    this.modalDefaultLoading();
    this.studentsService.getMyRoles().subscribe(
      (roles) => {
        roles.forEach((role) => {
          if (role.role.name == 'Agrupacion' || role.role.name == 'Registro y Control') {
            this.isAuth = true;
            this.mySchool = role.SchoolID;
          }
          if (role.role.name == 'admin') {
            this.isAuth = true;
            this.mySchool = role.SchoolID;
            this.admin = true;
          }
        });

        // if (!this.isAuth) this.router.navigate(['/']);
        /** TODO: REvisar permisos celic */

        this.getCourses();
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/']);
      }
    );
  }

  private getCourses() {
    this.coursesService.getCoursesBySchool(this.mySchool).subscribe((p) => {
      this.coursesList = p.sort(function (a, b) {
        return a['NameCourse'].localeCompare(b['NameCourse']);
      });
      this.coursesListFull = this.coursesList;
      this.modalClose();
    });


    this.coursesService.getCategories().subscribe((p) => {
      this.categoriesList = p
    });
  }

  ///////////MAIN SELECTORS
  public onSelectCategory(): void {
    this.coursesList = this.coursesListFull.filter(course => {
      let ismatch = false
      this.categorySelected?.TypeCourses.forEach(type => {
        if (course.TypeCourseID == type.id)
          ismatch = true
      });
      return ismatch;
    })
  }

  public onSelectCourse(): void {
    this.studentsList = [];

    this.validatedStudentsList = [];
    this.pendingStudentsList = [];
    this.resetGroupsData();

    this.modalDefaultLoading();
    this.coursesService.getAllOffersByCourseId(this.selectedCourse.id).subscribe((offers) => {
      this.offersList = offers;
      this.modalClose();
    });

    this.coursesService.getAllSubjectByCourseId(this.selectedCourse.id).subscribe((subjects) => {
      this.subjectsList = subjects;
    });
  }

  public onSelectOffer(): void {
    this.modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor',
      90000
    );
    ///Reset variables
    this.validatedStudentsList = [];
    this.pendingStudentsList = [];
    this.studentsOriginListGroups = [];

    this.setStudentsListGroup();
    this.setTotalsOAP();
    this.setCourseGroups();
    this.geTotalUserCourses();
  }

  public onSelectSubject(): void {
    if (this.selectedSubject === null) {
      this.onSelectOffer();
      return;
    }
    this.resetGroupsData();
    this.modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor!',
      90000
    );

    ///Reset variables
    this.validatedStudentsList = [];
    this.pendingStudentsList = [];
    this.studentsOriginListGroups = [];
  }


  private setStudentsListGroup(): void {
    this.modalDefaultLoading();
    this.studentsService.getFromCourseOfferId(this.selectedOffer.id).subscribe((students) => {
      if (students.length === 0) {
        this.modalsUserService.alert(
          'Información',
          'No hay estudiantes inscritos en esta oferta.',
          'info'
        );
        return;
      }

      this.studentsList = students;
      this.modalClose();
      this.modalsUserService.dismiss();
      this.validateDataFromUsers();
      this.geTotalUserCourses();
    });
  }

  public usersWithoutCompleteInfo: string[] = [];
  private validateDataFromUsers(): void {
    this.usersWithoutCompleteInfo = [];
    this.studentsList.forEach(student => {
      if (
        student.UserDocuments?.length == 0 ||
        student.ContactInfos?.length == 0 ||
        !student.UserCourse
      ) this.usersWithoutCompleteInfo.push(student.getConcatenatedName());
    });
  }

  private geTotalUserCourses() {
    const approved = this.studentsList.filter((student) => student.UserCourse?.IsAcepted).length;
    const unapproved = this.studentsList.filter((student) => !student.UserCourse?.IsAcepted).length;

    this.totalApproved = approved;
    this.totalUnapproved = unapproved;
  }

  private setStudentsListSubject(): void {
    this.modalDefaultLoading();
    this.studentsService.getFromSubjectGroupIds(this.originSubjecGroup.map((e) => e.id)).subscribe((students) => {
      if (students.length === 0) {
        this.modalsUserService.alert(
          'Información',
          'No hay estudiantes inscritos en esta materia.',
          'info'
        );
        return;
      }
      this.studentsList = students;
      this.modalsUserService.dismiss();
    });
  }

  private setCourseGroups() {
    this.originGroups = [];
    this.coursesOffersService
      .getCourseGroups(this.selectedOffer.id)
      .subscribe((groups) => {
        if (!groups) return;
        this.originGroups = groups;
      });
  }

  private setSubjectGroups() {
    this.originSubjecGroup = [];
    const filter = {
      where: {
        and: [
          { CourseOferID: this.selectedOffer.id },
          { SubjectID: this.selectedSubject.id },
        ],
      },
    };

    this.coursesOffersService
      .getSubjectGroups(JSON.stringify(filter))
      .subscribe((groups) => {
        if (!groups) return;
        this.originSubjecGroup = groups;
        this.setStudentsListSubject();
      });
  }

  ///////////GROUP  OPTION

  resetGroupsData() {
    this.studentsOriginListGroups = [];
    this.studentsDestinationListGroups = [];

    this.originGroups = [];
    this.originSubjecGroup = [];
    this.destinationGroups = [];
    this.destinationSubjecGroup = [];
    this.secondaryStudentsList = [];
  }

  setDestinationCourseGroups(offerId) {
    this.destinationGroups = [];
    this.coursesOffersService.getCourseGroups(offerId).subscribe((groups) => {
      if (!groups) return;
      this.destinationGroups = groups;
    });
  }

  public selectOriginGroup(event) {
    let filterProperty = ['', ''];

    if (this.changeStudentsGroup)
      filterProperty = ['UserCourse', 'CourseGroupID'];
    else filterProperty = ['UserSubject', 'SubjectGroupID'];

    const idGroupSelected: number = event.target.value;
    this.groupIdSelected = idGroupSelected;
    if (!idGroupSelected || idGroupSelected === null) return;
    this.studentsOriginListGroups = [];
    this.studentsOriginListGroups = this.studentsList.filter(
      (st) => st[filterProperty[0]][filterProperty[1]] == idGroupSelected
    );
  }

  public selectDestinationOfferGroup(event) {
    console.log('selectDestinationOfferGroup', event.target.value)
    const idOfferSelected = event.target.value;
    console.log('idOfferSelected', idOfferSelected)
    if (!idOfferSelected || idOfferSelected === null) return;

    // this.selectedDestinationOffer = this.offersList.find(
    console.log('allOffersList', this.allOffersList);
    this.selectedDestinationOffer = this.allOffersList.find(
      (offer) => offer.id == idOfferSelected
    );
    console.log('this.selectedDestinationOffer', this.selectedDestinationOffer);
    this.modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor',
      90000
    );

    this.studentsService
      .getFromCourseOfferId(idOfferSelected)
      .subscribe((students) => {
        this.secondaryStudentsList = students;

        // if ( this.secondaryStudentsList.length === 0 ) {
        //   this.modalsUserService.alert(
        //     'Información',
        //     'No hay estudiantes inscritos en esta oferta.',
        //     'info'
        //   );
        //   return;
        // }
        this.modalsUserService.dismiss();
        this.setDestinationCourseGroups(idOfferSelected);
      });
  }
  public selectDestinationOfferSubject(event) {
    const idOfferSelected = event.target.value;
    if (!idOfferSelected || idOfferSelected === null) return;

    this.selectedDestinationOffer = this.offersList.find(
      (offer) => offer.id == idOfferSelected
    );
    this.modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor',
      90000
    );

    this.destinationSubjecGroup = [];
    const filter = {
      where: {
        and: [
          { CourseOferID: this.selectedDestinationOffer.id },
          { SubjectID: this.selectedSubject.id },
        ],
      },
    };

    this.coursesOffersService
      .getSubjectGroups(JSON.stringify(filter))
      .subscribe((groups) => {
        if (!groups) return;
        this.destinationSubjecGroup = groups;

        this.studentsService
          .getFromSubjectGroupIds(this.destinationSubjecGroup.map((e) => e.id))
          .subscribe((students) => {
            if (students.length === 0) {
              this.modalsUserService.alert(
                'Información',
                'No hay estudiantes inscritos en esta materia.',
                'info'
              );
              return;
            }
            this.secondaryStudentsList = students;
            this.modalsUserService.dismiss();
          });
      });
  }

  public selectDestinationGroup(event) {
    let filterProperty = ['', ''];
    if (this.changeStudentsGroup)
      filterProperty = ['UserCourse', 'CourseGroupID'];
    else filterProperty = ['UserSubject', 'SubjectGroupID'];

    this.idDestinationGroupSelected = Number(event.target.value);
    if (
      !this.idDestinationGroupSelected ||
      this.idDestinationGroupSelected === null
    )
      return;
    // this.studentsDestinationListGroups = [];
    // this.studentsDestinationListGroups = this.secondaryStudentsList.filter(
    //   ( st ) => {
    //     return (
    //       st[ filterProperty[ 0 ] ][ filterProperty[ 1 ] ] ==
    //       this.idDestinationGroupSelected
    //     );
    //   }
    // );
  }

  // Cambiar usuarios de Oferta
  public saveGroupChanges(e) {
    const destination = e.destinationList[this.idDestinationGroupSelected];
    console.log('------>', e)
    console.log('------>', destination)
    console.log('------>', this.idDestinationGroupSelected)
    console.log('------>', this.selectedDestinationOffer)
    console.log('------>', this.idDestinationGroupSelected)
    if (
      (destination && destination.length == 0) ||
      this.selectedDestinationOffer == null ||
      this.selectedDestinationOffer == undefined ||
      this.idDestinationGroupSelected == null ||
      this.idDestinationGroupSelected == undefined
    ) {
      this.modalsUserService.alert(
        'Error',
        'Debe elegir una oferta, un grupo y al menos un estudiante.',
        'error'
      );
      return;
    }

    const moves = destination.map((st: Student) => ({
      UserId: st.id,
      UserCourseId: st.UserCourse.id,
      NewCourseGroupID: this.idDestinationGroupSelected,
      NewCourseOfferID: this.selectedDestinationOffer.id,
      PreviusCourseGroupID: Number(this.groupIdSelected),
      PreviusCourseOfferID: this.selectedOffer.id
    }));

    this.modalsUserService.infiniteWaitMessage(
      'Cargando',
      'Un momento por favor, esto puede tomar un tiempo dependiendo de su conexión a internet'
    );

    let userListToMoveObservable = moves.map((dataIterated) => {
      const dataToSend = {
        id: dataIterated.UserCourseId,
        IsTransferred: true,
        CourseGroupIDTransferred: dataIterated.NewCourseGroupID,
        CourseOfferIDTransferred: dataIterated.NewCourseOfferID
      }
      return this.studentsService.patchUserCourses(dataToSend).pipe(
        switchMap(responseUserCourse => {
          let filterUserCourses = {
            where: {
              and: [
                { UserID: responseUserCourse.UserID },
                { CourseOferID: this.selectedDestinationOffer.id },
                { IsAcepted: true }
              ]
            }
          }
          return this.studentsService.getUserCourses(JSON.stringify(filterUserCourses)).pipe(map((userCourses) => ({ userCourses, responseUserCourse })));
        }),
        switchMap(data => {
          let { userCourses, responseUserCourse } = data;
          if (userCourses && userCourses.length > 0) {
            let userInfo = {
              id: userCourses[0].id,
              IsTransferred: false,
              CourseGroupIDTransferred: null,
              CourseOfferIDTransferred: null,
              CourseGroupID: this.idDestinationGroupSelected
            }
            return this.studentsService.patchUserCourses(userInfo).pipe(map(() => ({ userCourses })));
          } else {
            let userInfo = {
              UserID: responseUserCourse.UserID,
              CourseOferID: this.selectedDestinationOffer.id,
              IsPaied: responseUserCourse.IsPaied,
              IsAcepted: true,
              IsDocument: responseUserCourse.IsDocument,
              CourseGroupID: this.idDestinationGroupSelected
            }
            return this.studentsService.createNewUserCourses(userInfo).pipe(map(() => ({ userCourses })));
          }
        }),
        switchMap(data => {
          let { userCourses } = data;
          const filter: string = JSON.stringify({
            where: {
              and: [
                { CourseGroupID: Number(this.groupIdSelected) },
                { CourseOferID: Number(this.selectedOffer.id) }
              ]
            }
          });
          return this.coursesService.getSubjectGroup(filter).pipe(map((allPreviusSubjectsGroups) => ({ allPreviusSubjectsGroups, userCourses })));
        }),
        switchMap(data => {
          let { allPreviusSubjectsGroups, userCourses } = data;
          let filter = JSON.stringify({
            where: {
              and: [{ SubjectGroupID: { inq: allPreviusSubjectsGroups.map(x => x.id) } }]
            }
          });
          return this.coursesService.getUserSubject(filter, dataIterated.UserId);
        }),
        switchMap(allPreviusUserSubjects => {
          const allPreviuesUserSubjectObservable = allPreviusUserSubjects.map(item => this.coursesService.patchUserSubject(item.id, { isAvailable: false }));
          return concat(...allPreviuesUserSubjectObservable).pipe(toArray());
        }),
        switchMap(data => {
          const filter: string = JSON.stringify({
            where: {
              and: [
                { CourseGroupID: Number(this.idDestinationGroupSelected) },
                { CourseOferID: Number(this.selectedDestinationOffer.id) }
              ]
            }
          });
          return this.coursesService.getSubjectGroup(filter);
        }),
        switchMap(data => {
          const dataToUpdate = data.map(item => this.coursesService.assignUserSubjectBackV2({
            SubjectGroupID: item.id,
            CourseGroupID: this.idDestinationGroupSelected
          }));
          return concat(...dataToUpdate).pipe(toArray());
        })
      );
    });

    concat(...userListToMoveObservable).pipe(toArray()).subscribe(
      (resultUpdate) => {
        this.modalsUserService.close();
        this.showConfirmModalAndReloadPage();
      }, (error) => {
        console.log('ERROR:', error);
        this.modalsUserService.alert(
          'Error',
          'Ocurrió un error en el proceso. Inténtelo de nuevo. Si el error persiste, contacte al soporte.'
        );
      }
    );

  }

  async showConfirmModalAndReloadPage() {
    let askUser = await this.modalsUserService.confirmWithSuccessAlert(
      'Hecho',
      `Los estudiantes han sido transferidos exitosamente.<br>Es necesario recargar la página para continuar.`,
      'Recargar'
    );
    window.location.reload();
  }

  getUserSubjects(SubjectGroupIDs: number[], userId: number) {
    let filter = JSON.stringify({
      where: {
        and: [{ UserID: userId }, { SubjectGroupID: { inq: SubjectGroupIDs } }]
      },
      include: [{ subjectGroup: 'userapp' }, { userRecord: ['midTerm', 'dateRecord'] }]
    });

    return this.coursesService.getUserSubject(filter, userId);
  }

  getSubjectGroups(courseGroupId: number) {
    const filter: string = JSON.stringify({ where: { and: [{ CourseGroupID: Number(courseGroupId) }, { CourseOferID: Number(this.selectedOffer.id) }] } });
    return this.coursesService.getSubjectGroup(filter);
  }


  public saveSubjectGroupChanges(e) {
    const destination = e.destinationList[this.idDestinationGroupSelected];
    let moves = [];
    if (destination && destination.length > 0) {
      destination.forEach((st: Student) => {
        moves.push({
          id: st.UserSubject.id,
          SubjectGroupID: this.idDestinationGroupSelected,
        });
      });

      this.studentsService.moveStudentsToSubjectGroup(moves).subscribe((rs) => {
        if (rs && rs.message.status == 422) {
          this.modalsUserService.alert(
            'Error',
            'Se ha presentado un error, por favor intenta más tarde.',
            'error'
          );
          return;
        }
        if (rs && rs.message)
          this.modalsUserService.alert(
            'Excelente',
            'Se han guardado los cambios exitosamente.',
            'success'
          );
      });
    }
  }

  ////Toggle Action, Change groups or Subjects
  public changeOptionsView(operation) {
    if (operation === 'group') {
      this.changeStudentsGroup = true;
      this.changeStudentsSubject = false;
    } else if (operation === 'subject') {
      this.changeStudentsGroup = false;
      this.changeStudentsSubject = true;
    } else if (operation === 'students') {
      this.changeStudentsGroup = false;
      this.changeStudentsSubject = false;
    }
  }

  /////////////////////////////OAP OPTIONS
  private setTotalsOAP() {
    this.oapTotalListBd = 0;
    this.coursesOffersService
      .getTotalValidationOAP(this.selectedOffer.id)
      .subscribe((total) => {
        if (!total || !total.count) return;
        this.oapTotalListBd = total.count;
      });
  }

  /**
   * Read excel File
   * @param event
   * @returns
   */
  async loadOAPFile(event) {
    swalLoading({ title: "Leyendo archivo...", message: 'Un momento por favor.' });

    const fileData = event.target.files[0];
    if (!fileData) {
      swalError({ title: "Error al leer el archivo", message: 'Por favor, inténtelo de nuevo.' });
      event.target.value = null;
      return;
    }

    //Convert to json
    const fileDataJson = await this.excelService.readFileToJson(fileData);
    if (fileDataJson.length == 0) {
      swalError({ title: "Documento sin datos", message: 'Por favor, asegúrese cargar un document con datos.' });
      event.target.value = null;
      return;
    }

    //Validate fields
    const objectKeys = Object.keys(fileDataJson[0]);
    const verifyColumn = (columnName) => objectKeys.find(e => e === columnName);

    //Si o si deben estar en mayúsculas.
    if (!(verifyColumn('Documento') && verifyColumn('Nombre'))) {
      swalError({ title: "Error en las columnas", message: 'El archivo debe tener las columnas: "Nombre" y "Documento"' });
      event.target.value = null;
      return;
    }

    swalConfirm({
      title: "Cargar Lista OAP",
      html: `¿Deseas cargar el archivo <strong> ${fileData.name}</strong> ?`,
      confirmCallback: () => this.uploadOAPFile(event, fileDataJson),
      cancelCallback: () => event.target.value = null
    })
  }

  /**
   * Upload OAP file
   * @param event
   * @param fileData
   */
  private uploadOAPFile(event: any, fileData: any) {
    const documentInfo = fileData;

    const mainColumns = documentInfo.map(e => ({
      document: e.Documento.toString().trim(), name: e.Nombre
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
    }));

    const validData = [];
    const invalidData = [];

    for (let i = 0; i < mainColumns.length; i++) {
      const fileUser = mainColumns[i];

      const foundUser = this.studentsList.find(student => {
        const studentName = (`${this.removeSpaces(student.Name1)} ${this.removeSpaces(student.Name2 || '')} ${this.removeSpaces(student.LastName1)} ${this.removeSpaces(student.LastName2 || '')}`)
        const studentDocument = (student.UserDocuments[0].Document || '').toString().trim();

        return fileUser.name == studentName && fileUser.document == studentDocument;
      });

      if(foundUser){
        validData.push( mainColumns.find(e => e.document === foundUser.UserDocuments[0].Document) );
        continue;
      }

      invalidData.push(fileUser);
    }

    event.target.value = null;

    this.oapList = {
      state: invalidData.length > 0 ? 'invalid' : 'valid',
      data: invalidData.length > 0 ? invalidData : validData
    };

    this.oapModal.fire();
  }

  /**
   * Upload validation OAP
   * @returns
   */
  public uploadToValidationOAP() {
    if(this.oapList.state === 'invalid'){
/*       const content = `
        <p style="margin-bottom: 10px !important; color:#514d6a; ">Los siguientes estudiantes no se pudieron validar, el nombre o el documento no coinciden.</p>

        <table style="width:100%;">
          <thead>
            <tr style="color:#514d6a;">
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Documento</th>
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Nombre</th>
            </tr>
          </thead>
          <tbody style="color:#514d6a;">
            ${this.oapList.data.map((e, i) => `
            <tr style="${i % 2 === 0 ? 'background-color: #1e438c0f;' : ''}">
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.document}</td>
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.name}</td>
            </tr>`).join('')}
          </tbody>
        </table>` */
      swalError({
        title: "Error.", 
        message: 'Los siguientes estudiantes no se pudieron validar, el nombre o el documento no coinciden.',
        confirmCallback:() => this.oapModal.fire()
      });

      return;
    }

    swalLoading({ title: "Cargando datos...", message: 'Un momento por favor.' });

    const oapStudents = this.oapList.data
      .map(oapData => {
        const studentData = this.studentsList.find(e => e.UserDocuments[0].Document === oapData.document);

        let names = '';
        let lastNames = '';

        if (studentData) {
          names = (`${this.removeSpaces(studentData.Name1)} ${this.removeSpaces(studentData.Name2 || '')}`);
          lastNames = (`${this.removeSpaces(studentData.LastName1)} ${this.removeSpaces(studentData.LastName2 || '')}`);
        }

        return {
          CourseOferID: this.selectedOffer.id,
          SchoolID: this.selectedOffer.SchoolID,
          Names: names,
          LastNames: lastNames,
          Document: oapData.document,
        }
      });

    const googleWsUsers = oapStudents.map(oapData => {
      const names = `${oapData.Names} ${oapData.LastNames}`;
      const newWsUser = this.splitName(names);

      const foundUser = this.studentsList.find(e => e.UserDocuments[0].Document == oapData.Document);

      const roleMappings: any[] = foundUser.roleMappings.filter(e => e.SchoolID === this.selectedOffer.SchoolID);
      const roles: any[] = roleMappings.length === 0 ? foundUser.roleMappings : roleMappings;

      const userData = {
        firstName: newWsUser.firstName,
        lastName: newWsUser.lastName,
        orgUnitPath: this.setUserGroup(roles),
        email: foundUser.CedocEmail,
        password: foundUser.ContactInfos[0].CellPhone
      }

      return userData;
    });

    const googleWsReq = googleWsUsers.map(user => this.coursesOffersService.createWsUser(user));
    this.coursesOffersService.uploadToValidationOAP(this.selectedOffer.id, oapStudents).pipe(
      switchMap(() => forkJoin(googleWsReq.map(
        req => req.pipe(
          catchError(error => {
            console.log(error);
            return of(null); //Returns null observable to handle the errors
          })
        )
      )))
    ).subscribe({
      complete: () => {
        this.validateOAP(false);
        this.setTotalsOAP();
      },
      error: err => {
        console.log(err);
        swalError({
          title:'Error',
          message: 'Error interno del servidor'
        })
      }
    });
  }

  validateOAP(showListModal: boolean) {
    if (!this.selectedOffer) return;
    if (this.oapTotalListBd == 0) {
      this.modalsUserService.alert('Aún no se han cargado estudiantes OAP');
      return;
    }
    this.coursesOffersService
      .getValidationOAP(this.selectedOffer.id)
      .subscribe((list) => {
        if (!list) return;
        this.oapListBd = [];
        this.oapListBd = list;
        this.oapList.data = list;
        if (showListModal) this.oapModal.fire();
        this.setCardsOAP();
        this.aprobarListaOAP();
      });
  }

    public async aprobarListaOAP() {
      let listAprovedIds = [];
      for await (let oapStudent of this.oapList.data) {
        let found = this.studentsList.find(
          (st) =>
            st.getCivilDocument() === oapStudent.Document ||
            st.getMilitarDocument() === oapStudent.Document
        );
  
        if (found && found.UserCourse.id && !found.UserCourse.IsAcepted)
          listAprovedIds.push(found.UserCourse.id);
      }

      this.modalsUserService.waitMessage(
        'Aprobando todos',
        'Un momento por favor',
        90000
      );
      this.coursesService
        .updateUsersCourseStateBatch(listAprovedIds, true, true, true)
        .subscribe((rs) => {
          this.modalsUserService.dismiss();
          if (!rs || rs.count == 0) return;
          this.modalsUserService.alert('Aprobación exitosa!', '', 'success');
          this.setStudentsListGroup();
          setTimeout(() => this.validateOAP(false), 3000);
        });
    }

  ///CARDS SIDE INFO
  setCardsOAP() {
    this.validatedStudentsList = [];
    this.pendingStudentsList = [];
    this.oapListBd.forEach((oapStudent) => {
      let found = this.studentsList.find(
        (st) =>
          st.getCivilDocument() === oapStudent.Document ||
          st.getMilitarDocument() === oapStudent.Document
      );
      if (found && found.UserCourse.IsAcepted)
        this.validatedStudentsList.push(found);
      else this.pendingStudentsList.push(oapStudent);
    });
  }

  async deleteOAPListFromBd() {
    let askUser = await this.modalsUserService.confirm(
      'Eliminar',
      'Confirma que desea eliminar la lista de estudiantes OAP, esta acción no se puede deshacer'
    );
    if (askUser.isConfirmed) {
      this.coursesOffersService
        .deleteListValidationOAP(this.selectedOffer.id)
        .subscribe((rs) => {
          this.oapListBd = [];
          this.oapTotalListBd = 0;
          this.modalsUserService.alert(
            'Eliminado',
            'Se ha eliminado la lista OAP correctamente'
          );
        });
    }
  }

  ///DOWNLOAD EXCEL LISTS
  PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();
  exportToExcel(toDispatch: string) {
    let dataToExport = [];
    switch (toDispatch) {
      case 'enroll':
        this.studentsList.forEach(st => {
          let data = {
            Nombre: st.getConcatenatedFirstName(),
            Apellidos: st.getConcatenatedLastName(),
            TipoDocumento: st.getTypeDocument(),
            Documento: st.getCivilDocument(),
            MilitarDocument: st.getMilitarDocument(),
            CorreoPersonal: st.getPersonalEmail(),
            CorreoInstitucional: st.CedocEmail,
          }
          if (this.PRINCIPAL_APP_NAME == 'celic') delete data.MilitarDocument;
          dataToExport.push(data);
        });
        this.excelService.exportAsExcelFile(dataToExport, 'Inscritos');
        break;
      case 'validatesOAP':
        this.validatedStudentsList.forEach(st => {
          let data = {
            Nombre: st.getConcatenatedFirstName(),
            Apellidos: st.getConcatenatedLastName(),
            TipoDocumento: st.getTypeDocument(),
            Documento: st.getCivilDocument(),
            MilitarDocument: st.getMilitarDocument(),
            CorreoPersonal: st.getPersonalEmail(),
            CorreoInstitucional: st.CedocEmail,
          }
          if (this.PRINCIPAL_APP_NAME == 'celic') delete data.MilitarDocument;
          dataToExport.push(data);
        });
        this.excelService.exportAsExcelFile(dataToExport, 'Validos');
        break;
      case 'pendingsOAP':
        this.pendingStudentsList.forEach((stOAP: any) => {
          dataToExport.push({
            Name: stOAP.Names + ' ' + stOAP.LastNames,
            Document: stOAP.Document,
          });
        });
        this.excelService.exportAsExcelFile(dataToExport, 'Pendientes');
        break;
      case 'allOAP':
        this.oapListBd.forEach((st) => {
          dataToExport.push({
            Names: st.Names,
            Document: st.Document,
          });
        });
        this.excelService.exportAsExcelFile(dataToExport, 'ListaOAP');
        break;
      case 'approved':
        this.studentsList.forEach((st) => {
          if (st.UserCourse?.IsAcepted) {
            let data = {
              Nombre: st.getConcatenatedFirstName(),
              Apellidos: st.getConcatenatedLastName(),
              TipoDocumento: st.getTypeDocument(),
              Documento: st.getCivilDocument(),
              MilitarDocument: st.getMilitarDocument(),
              CorreoPersonal: st.getPersonalEmail(),
              CorreoInstitucional: st.CedocEmail,
            }
            if (this.PRINCIPAL_APP_NAME == 'celic') delete data.MilitarDocument;
            dataToExport.push(data);
          }
        });
        this.excelService.exportAsExcelFile(dataToExport, 'aprobados');
        break;
      case 'unapproved':
        this.studentsList.forEach((st) => {
          if (!st.UserCourse?.IsAcepted) {
            let data = {
              Nombre: st.getConcatenatedFirstName(),
              Apellidos: st.getConcatenatedLastName(),
              TipoDocumento: st.getTypeDocument(),
              Documento: st.getCivilDocument(),
              MilitarDocument: st.getMilitarDocument(),
              CorreoPersonal: st.getPersonalEmail(),
              CorreoInstitucional: st.CedocEmail,
            }
            if (this.PRINCIPAL_APP_NAME == 'celic') delete data.MilitarDocument;
            dataToExport.push(data);
          }
        });
        this.excelService.exportAsExcelFile(dataToExport, 'no_aprobados');
        break;
    }
  }

  /**
   * Create or edit the current name and cedoc mail.
   * @param event
   * @returns
   */
  protected editUser(event: any) {
    const emailDomain = event.student.CedocEmail.split('@')[1];
    this.modalsUserService.editUser('Editar usuario', (data) => {
      //User basic info to update
      const userAppData = {
        Name1: this.removeSpaces(data.name1),
        Name2: this.removeSpaces(data.name2 || ''),
        LastName1: this.removeSpaces(data.lastName1),
        LastName2: this.removeSpaces(data.lastName2 || ''),
        CedocEmail: '',
        email: ''
      }

      //Gets the current user data
      const currentEmail = event.student.CedocEmail;
      const isTest = !environment.production;

      //Gets the updated user data
      const newWsUser = this.splitName(`${userAppData.Name1} ${userAppData.Name2} ${userAppData.LastName1} ${userAppData.LastName2}`);
      let newEmail = `${isTest ? 'test-' : ''}${newWsUser.userName}@${emailDomain}`;
      userAppData.CedocEmail = newEmail;
      userAppData.email = newEmail;

      //Sets the new Google Workspace user
      const googleWsUsers = {
        firstName: newWsUser.firstName + " " + newWsUser.secondName,
        lastName: newWsUser.lastName,
        password: data.cellphone,
        currentEmail,
        newEmail,
      };

      this.modalDefaultLoading();
      this.validateBeforeUpdateUser(newEmail, data, event).pipe(
        switchMap((resposneData: any) => {

          //We also can check if document or celphone is different before sending the request
          if (resposneData && resposneData.length > 0) {
            const userData = resposneData[0];

            if (userData.UserDocuments[0].Document == data.document) {
              this.modalsUserService.alert('Error', `El correo: ${userData.CedocEmail} no se pudo actualizar porque ya está en uso`, 'error', 'Ok');
              return of(null);
            } else {
              //Update cedoc email
              newEmail = `${isTest ? 'test-' : ''}${newWsUser.userName}${data.document.slice(-2)}@${emailDomain}`;
              userAppData.CedocEmail = newEmail;
              userAppData.email = newEmail;
              googleWsUsers.newEmail = newEmail;
            }
          }

          const userObs = (event.student.UserCourse && event.student.UserCourse.IsAcepted) ?
            this.studentsService.updateUser(event.student.id, userAppData).pipe(
              switchMap((user) =>
                this.coursesOffersService.updateWsUser(googleWsUsers).pipe(
                  catchError(error => {
                    console.log(error);

                    //If the user is not found, it is because the user does not exist.
                    if (error.status === 404 || error.status === 429)
                      return of(null)

                    return throwError(error);
                  }),
                  map(e => user)
                )
              )
            ) :
            this.studentsService.updateUser(event.student.id, userAppData)

          return forkJoin({
            document: this.studentsService.updateUserDocuments(event.student.id, event.student.UserDocuments[0].id, { Document: data.document }),
            cellphone: this.studentsService.updateUserCellphone(event.student.id, event.student.ContactInfos[0].id, { CellPhone: data.cellphone }),
          }).pipe(
            switchMap(resp => {
              return userObs.pipe(map(user => ({ user, ...resp })))
            })
          )
        })
      ).subscribe({
        next: (resp: any) => {
          console.log(resp);

          if (resp) {
            const { user, cellphone, document } = resp;
            event.student.Name1 = user.Name1;
            event.student.Name2 = user.Name2 || '';
            event.student.LastName1 = user.LastName1;
            event.student.LastName2 = user.LastName2 || '';
            event.student.CedocEmail = user.CedocEmail;
            event.student.UserDocuments[0].Document = document.Document;
            event.student.ContactInfos[0].CellPhone = cellphone.CellPhone;
            this.modalsUserService.alert('Hecho', `Usuario actualizado con éxito: ${user.CedocEmail}`, 'info', 'Ok');
          }

          this.modalsUserService.alert('Hecho', `Usuario actualizado con éxito`, 'info', 'Ok');

        }, error: (err) => {
          const errorMessage = (err.error && err.error.error && err.error.error.message) ? err.error.error.message : 'Error en al actualizar el usuario.';
          this.modalsUserService.alert('Error', `${errorMessage}`, 'error', 'Ok');
          console.log(err);
        }
      })
    }, event.student)
  }

  private validateBeforeUpdateUser(newEmail: string, data: any, event: any) {
    //If I have the student rol this filter will not work
    const contactInfoFilter = JSON.stringify({
      where: { or: [{ CellPhone: data.cellphone }, /* {Email:data... } */] }
    });

    const userFilter = JSON.stringify({
      where: { CedocEmail: newEmail },
      include: ['UserDocuments']
    });

    const findUser = (event.student.CedocEmail != newEmail) ? this.studentsService.getUser(userFilter) : of(null);
    const findCellphone = (event.student.ContactInfos[0].CellPhone != data.cellphone) ? this.studentsService.getContactInfo(contactInfoFilter) : of(null);

    return findCellphone.pipe(
      switchMap(resp => {
        if (resp && resp.length > 0) {
          this.modalsUserService.alert('Error', `El número de celular: ${resp[0].CellPhone} ya está en uso`, 'error', 'Ok');
          return of(null);
        }

        return findUser;
      })
    )
  }

  /* Execute when users click on the button (Aceptar/Rechazar), this method update the studen state for the selected course */
  public async toggleStudent($event: { student: Student; type: string }) {
    if (!$event.student || !$event.type || $event.type === '')
      return;

    const password = $event.student.ContactInfos[0].CellPhone;

    //Filter all roles by offer school
    const roleMappings: any[] = $event.student.roleMappings.filter(e => e.SchoolID === this.selectedOffer.SchoolID);
    const roleIds: number[] = roleMappings.map(e => e.roleId);
    const isCandidate: boolean = roleIds.includes(10) && !roleIds.includes(2) && roleMappings.length > 0;

    const {
      confirmTitle,
      confirmMsg,
    } = this.setTitleMessageConfirmToggleStudent($event);

    const askUser = await this.modalsUserService.confirm(
      confirmTitle,
      confirmMsg
    );

    let userCourseId = [];
    if ($event.student.UserCourse.id)
      userCourseId.push($event.student.UserCourse.id);

    if (askUser.isConfirmed) {
      let $updateStudent: Observable<any>;
      let $updateGoogleWsUser: Observable<any> = of(null);
      let $creatStudentRole: Observable<any> = of(null);
      let optimisticUpdate: Student = $event.student;

      const newWsUser = this.splitName(`${this.removeSpaces($event.student.Name1)} ${this.removeSpaces($event.student.Name2 || '')} ${this.removeSpaces($event.student.LastName1)} ${this.removeSpaces($event.student.LastName2 || '')}`);
      const email = $event.student.CedocEmail;

      const googleWsUsers = {
        firstName: newWsUser.firstName,
        lastName: newWsUser.lastName,
        orgUnitPath: this.setUserGroup(roleMappings),
        email,
        password
      };

      switch ($event.type) {
        case 'accepted':
        case 'state':
          // GOOGLE WORK SPACE LOGIC
          let isAccepted = !$event.student.UserCourse.IsAcepted;

          //If accepted we create the request to register the user in Google Admin SDK
          if (isAccepted)
            $updateGoogleWsUser = this.coursesOffersService.createWsUser(googleWsUsers);

          //If accepted and not have the student role, but have the candidate role, then we change it to student role.
          if (isAccepted && isCandidate) {
            const roleToUpdate = roleMappings.find((e: any) => e.roleId === 10)?.id;
            const data = { roleId: 2 };

            $creatStudentRole = this.coursesOffersService.updateStudentRole($event.student.id, roleToUpdate, data);
          }

          $updateStudent = this.coursesService.updateUsersCourseStateBatch(
            userCourseId,
            !$event.student.UserCourse.IsAcepted,
            true,
            true
          );

          optimisticUpdate.UserCourse.IsAcepted = !$event.student.UserCourse.IsAcepted;
          break;

        case 'documents':
          $updateStudent = this.coursesService.updateUsersCourseStateBatch(
            userCourseId,
            $event.student.UserCourse.IsAcepted,
            $event.student.UserCourse.IsPaied,
            !$event.student.UserCourse.IsDocument
          );
          optimisticUpdate.UserCourse.IsDocument = !$event.student.UserCourse
            .IsDocument;
          break;
        case 'payment':
          $updateStudent = this.coursesService.updateUsersCourseStateBatch(
            userCourseId,
            $event.student.UserCourse.IsAcepted,
            !$event.student.UserCourse.IsPaied,
            $event.student.UserCourse.IsDocument
          );
          optimisticUpdate.UserCourse.IsPaied = !$event.student.UserCourse
            .IsPaied;
          break;
        default:
          break;
      }

      this.modalDefaultLoading();
      forkJoin({
        student: $updateStudent,
        role: $creatStudentRole
      }).pipe(
        switchMap(({ student, role }: any) => $updateGoogleWsUser.pipe(
          catchError(error => {
            console.log(error);
            if (error.status === 400)  //If the user already exists
              return of(null)

            return throwError(error);
          }),
          map((googleWsUser) => ({ student, role, googleWsUser })),
        ))
      ).subscribe({
        next: ({ student, role }) => {
          if (student && student.count > 0) {
            this.modalsUserService.alert('Hecho', `El usuario ${email} se ha actualizado`, 'info', 'Cerrar');
            $event.student = optimisticUpdate;
          } else this.modalClose();
        }, error: (err) => {
          console.log(err);
          this.modalsUserService.alert('Error', `Ha ocurrido un error`, 'error');
        }
      });
    }
  }

  /**
   * Validate user role
   * @param allRoles
   * @returns
   */
  private setUserGroup(allRoles: any[]) {
    const studentSchoolName = allRoles[0].school.NameTSchool.toUpperCase();
    const roleIds = allRoles.map(e => e.roleId);
    const isCelic = this.SCHOOL_NAME === 'CELIC';

    if (isCelic)
      return `/${studentSchoolName}/${roleIds.includes(3) ? 'Docentes' : 'Estudiantes'}`

    return roleIds.includes(3) ? '/DOCENTES' : '/ESTUDIANTES'
  }

  private setTitleMessageConfirmToggleStudent(event: { student: Student; type: string; }) {
    const { student, type } = event;
    const isAccepted = student.UserCourse.IsAcepted;
    const isDocument = student.UserCourse.IsDocument;
    const isPaid = student.UserCourse.IsPaied;

    const getAction = (condition: boolean) => condition ? 'Rechazar' : 'Aceptar';
    const getConfirmTitle = (action: string, subject: string) =>
      `¿${action} ${subject}?`;
    const getConfirmMsg = (action: string, subject: string) =>
      `¿Deseas ${action.toLowerCase()} el ${subject} <strong>${student.getConcatenatedName()}</strong>, para este curso?`;

    let confirmTitle = '';
    let confirmMsg = '';

    if (type === 'state' || type === 'accepted') {
      const action = getAction(isAccepted);
      const subject = 'usuario';
      confirmTitle = getConfirmTitle(action, subject);
      confirmMsg = getConfirmMsg(action, subject);
    } else if (type === 'documents') {
      const action = getAction(isDocument);
      const subject = 'documentación';
      confirmTitle = getConfirmTitle(action, subject);
      confirmMsg = getConfirmMsg(action, subject);
    } else if (type === 'payment') {
      const action = getAction(isPaid);
      const subject = 'pago';
      confirmTitle = getConfirmTitle(action, subject);
      confirmMsg = getConfirmMsg(action, subject);
    }

    return { confirmTitle, confirmMsg };
  }

  splitName(fullName) {
    const names = fullName.split(" ");
    const firstName = `${names[0]}`;
    const secondName = `${names[1]}`;
    const lastName = names.slice(-2).join(" ");
    const userName = this.removeAccents(this.removeSpaces(`${firstName}${lastName}`)); //Join and remove spaces

    return {
      firstName,
      secondName,
      lastName,
      userName
    }
  }

  removeSpaces(value: string): string {
    return value.toLowerCase().replace(/\s+/g, '');
  }

  removeAccents(value: string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  modalDefaultLoading(): void {
    this.modalsUserService.loadingMessage();
  }

  modalClose(): void {
    this.modalsUserService.close();
  }

}

