import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/courses/category.model';
import { HttpService } from './http.service';
import { Subject } from 'src/app/shared/models/subjects/subject';
import { UserCourse } from 'src/app/shared/models/users/userCourse.model';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(private _httpService: HttpService) {}

  getAllSchools(): Observable<any> {
    return this._httpService.httpGet(`/Schools`).pipe(take(1));
  }

  getAllSchoolsWithAllData(): Observable<any> {
    return this._httpService.httpGet(`/Schools?filter={"include":{"Courses":"CourseOfer"}}`).pipe(take(1));
    //return this._httpService.httpGet(`/Schools?filter={"Courses":"CourseOfer"}`).pipe(take(1));
  }

  getCoursesBySchool(id: number): Observable<any> {
    return this._httpService.httpGet(`/Schools/${id}/Courses`).pipe(take(1));
  }

  getCoursesWithOffersBySchool(id: number): Observable<any> {
    return this._httpService.httpGet(`/Schools/${id}/Courses?filter={"include":"CourseOfer"}`).pipe(take(1));
  }

  getCategories(): Observable<Category[]>{
    const filter = { include: ["TypeCourses"]}

    const url: string = `/Categories?filter=${JSON.stringify(filter)}`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getAllOffersByCourseId(id: number): Observable<any> {
    return this._httpService.httpGet(`/Courses/${id}/CourseOfer`).pipe(take(1));
  }

  getAllSubjectByCourseId(id: number): Observable<any> {
    return this._httpService.httpGet(`/Courses/${id}/Subjects`).pipe(take(1));
  }

  /* Acept or denay students to courses by studentId */
  updateUsersCourseStateBatch(
    ids: Array<number>,
    areAccepted: boolean,
    arePaied: boolean,
    documentValidated: boolean
  ): Observable<any> {
    if (ids.length === 0) return of(null);
    const url = `UserCourses/update?where={"id":{"inq":${JSON.stringify(
      ids
    )}}}`;
    //data=[]
    const data = {
      IsAcepted: areAccepted,
      IsPaied: arePaied,
      IsDocument: documentValidated,
    };
    return this._httpService.httpPost(url, data).pipe(take(1));
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  getUserSubject(filter:any,userId): Observable<any>{
    return this._httpService.httpGet(`/Userapps/${userId}/UserSubjects?filter=${filter}`).pipe(take(1));
    // const userSubjects = this.http.get( `${ this.baseUrl }/Userapps/${ userId }/UserSubjects?filter=${filter}`, { headers: this.getHeaders() } )
    // return userSubjects;
  }

  getSubjectGroup( filter:any ): Observable<any> {
    return this._httpService.httpGet(`/SubjectGroups?filter=${filter}`).pipe(take(1));
    // const userCourse = this.http.get( `${ this.baseUrl }/SubjectGroups?filter=${filter}`, { headers: this.getHeaders() } )
    // return userCourse;
  }

  UpdtateAssociationUserCourse( valores ): Observable<any> {
    console.debug( "llego la variable" + JSON.stringify( valores ) );
    return this._httpService.httpPatch(`/UserCourses/${ valores.id }`,JSON.stringify( valores )).pipe(take(1));
    // return this.http.patch( `${ this.baseUrl }/UserCourses/${ valores.id }`,JSON.stringify( valores ),
    //     { headers: this.putHeaders() } );
  }

  patchUserSubject(id:number, data:any): Observable<any>{
    return this._httpService.httpPatch(`/UserSubjects/${ id }`, data).pipe(take(1));
    // const userSubjects = this.http.patch( `${ this.baseUrl }/UserSubjects/${ id }`,data, { headers: this.getHeaders() } )
    // return userSubjects;
  }

  assignUserSubjectBackV2( object: Subject ): Observable<any> {
    return this._httpService.httpPost(`/UserSubjects/assignUserSubject`,JSON.stringify( object )).pipe(take(1));
    // let localUrl = `${ this.baseUrlBackV2 }/UserSubjects/assignUserSubject`;
    // let courses$ = this.http.post( localUrl, JSON.stringify( object ), { headers: this.putHeaders() } ).pipe(
    //   catchError( err => this.authService.errorMessages( err ) )
    // )
    // return courses$;
  }

  assignUserCourse<T>(data:T){
    return this._httpService.httpPost(`/UserCourses`, data)
  }
}
