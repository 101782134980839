import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
  {
    path: 'enroll',
    loadChildren: () =>
      import(
        './pages/manage-students-courses/manage-students-courses-page.module'
      ).then((m) => m.StudentsCoursesPageModule),
  },
  {
    path: 'enroll/enroll',
    loadChildren: () =>
      import(
        './pages/manage-students-courses/manage-students-courses-page.module'
      ).then((m) => m.StudentsCoursesPageModule),
  },

  {
    path: 'enroll/courses',
    loadChildren: () =>
      import('./pages/subjects/subjects-page.module').then(
        (m) => m.SubjectsPageModule
      ),
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
