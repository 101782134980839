import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { error } from 'console';
import { concat, Subject } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { ModalsUserService } from 'src/app/services/alerts/modals-user.service';
import { CoursesOffersService } from 'src/app/services/http/courses-offers.service';
import { CoursesService } from 'src/app/services/http/courses.service';
import { CourseGroup } from 'src/app/shared/models/courses/course-group.model';
import { CourseOffer } from 'src/app/shared/models/courses/courseOffer.model';
import { School } from 'src/app/shared/models/schools/school.model';
import { Student } from 'src/app/shared/models/users/student.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'assign-students-course',
  templateUrl: './assign-students-course.component.html',
  styleUrls: ['./assign-students-course.component.css'],
})
export class AssignStudentsCourseComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() students:Student[] = [];

  allSchoolsList: School[] = [];
  schoolSelected: number = null;

  offerList:CourseOffer[] = [];
  selectedOffer:number | null = null;

  constructor (
    private _modalsUserService: ModalsUserService,
    private _coursesService: CoursesService,
    private _coursesOffersService:CoursesOffersService
  ) {}

  ngOnInit(): void {
    this._coursesService.getAllSchools().subscribe( ( schoolsList ) => {
      this.allSchoolsList = schoolsList;
      this._modalsUserService.dismiss();
    });
  }
  selectSchoolChange(event) {
    Swal.fire({
      title:'Cargando',
      text:  'Un momento por favor',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    this._coursesService.getCoursesWithOffersBySchool(this.schoolSelected).subscribe( ( coursesList ) => {
      this.offerList = [];
      coursesList.forEach(courseOfferIterate => {
        this.offerList.push(...courseOfferIterate.CourseOfer);
      });
      Swal.close();
    });
  }

  closeView() {
    this.close.emit('close');
  }

  saveCourse() {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: 'Esta acción asignará un nuevo curso para los estudiantes.',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      allowOutsideClick: false,
      allowEscapeKey: false, 
    }).then(value => {
      if(value.isConfirmed){
        Swal.fire({
          title:'Guardando',
          text: 'Un momento por favor',
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const obs = this.students.map( s => this._coursesService.assignUserCourse({
          CourseOferID: this.selectedOffer,
          UserID: s.id
        }));

        concat(...obs).pipe(
          toArray()
        ).subscribe({
          next:() => {
            Swal.fire({
              icon: 'success',
              title: '¡Hecho!',
              text: 'Las inscripciones se realizaron con éxito.',
              confirmButtonText: 'Aceptar',
            }).then(value => {
              if(value.isConfirmed)
                this.close.emit();
            })
          },error:(err) => {
            Swal.fire({
              icon: 'error',
              title: '¡Ha ocurrido un error!',
              text: 'Por favor, revise la consola para obtener más detalles.',
            })
            console.log(err);
          }
        })
      }
    })
  }
}
