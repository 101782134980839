import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Student } from 'src/app/shared/models/users/student.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'enroll-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css'],
})
export class StudentListComponent implements OnInit {
  @Input()
  detailsActive: boolean = false;
  @Input()
  checkeable: boolean = false;
  @Input()
  draggable: boolean = false;
  @Input()
  verticalPagination: boolean = false;

  /////DROP VARIABLES & EVENTS
  @Input() idList: string = "";
  @Input() idListConect: string = "";

  @Output('editUser') editUser: EventEmitter<any> = new EventEmitter();

  @Output() changeListDropped: EventEmitter<
    CdkDragDrop<Student[]>
  > = new EventEmitter();

  @Output() changeSudentsListChecked: EventEmitter<
    Array<Student>
  > = new EventEmitter();

  public findText: string;
  _studentsListFull: Array<Student> = [];

  /////PAGINATION
  _studentsList: Array<Student> = [];

  private offSetPerPage: number = 10;
  pointerPage: number = 0;
  nextPageEnable: boolean = true;
  previousPageEnable: boolean = false;

  ///STUDENT CARD OPT
  @Output() toggleStudentCourseState: EventEmitter<{
    student: Student;
    type: string;
  }> = new EventEmitter();

  /////STUDENTS CHECKED
  checkedStudentsList: Array<Student> = [];

  @Output() selectedStudents:EventEmitter<Student[]> = new EventEmitter();
  private _selectedStudents:Student[] = [];
  public selectAllStudents:boolean = false;

  PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  
  constructor() {}

  ngOnInit(): void {}

  @Input()
  set studentsList(studentsList: Array<Student>) {
    this._studentsListFull = Object.assign([], studentsList);
    this._studentsList = this._studentsListFull;
    this.setPagination();
  }

  public onFindChanged() {
    if (this.findText === '') this._studentsList = this._studentsListFull;
    else
      this._studentsList = this._studentsListFull.filter(
        (student) =>
          student.CedocEmail.toLowerCase().includes(this.findText.toLowerCase()) ||
          student.UserDocuments[0].Document.includes(this.findText)
      );
  }

  drop(event: CdkDragDrop<Student[]>) {
    this.changeListDropped.emit(event);
  }

  studentChecked(studentChecked: Student) {
    this.checkedStudentsList.push(studentChecked);
    this.changeSudentsListChecked.emit(this.checkedStudentsList);
  }

  studentUnChecked(studentUnChecked: Student) {
    this.checkedStudentsList = this.checkedStudentsList.filter(
      (st) => st.id !== studentUnChecked.id
    );
    this.changeSudentsListChecked.emit(this.checkedStudentsList);
  }

  setPagination() {
    if (this._studentsListFull.length == 0) return;
    this._studentsList = this._studentsListFull.slice(0, this.offSetPerPage);
  }

  notifyToggleStudent(event: Student, type: string) {
    this.toggleStudentCourseState.emit({
      student: event,
      type: type,
    });
  }

  notifyEditUser(event: Student) {
    this.editUser.emit( { student:event });
  }

  trackItems(index: number, item: Student) {
    if (!item || !item.id) return null;
    return item.id;
  }

  nextPage() {
    if (this._studentsListFull.length < this.offSetPerPage) return;
    if (!this.verticalPagination) {
      this.pointerPage++;
      let increment: number =
        this.pointerPage * this.offSetPerPage + this.offSetPerPage;
      increment =
        increment > this._studentsListFull.length
          ? this._studentsListFull.length
          : increment;
      this._studentsList = this._studentsListFull.slice(
        this.pointerPage * this.offSetPerPage,
        increment
      );
      this.nextPageEnable = increment < this._studentsListFull.length;
      this.previousPageEnable = true;
    } else {
      const diff = this._studentsListFull.length - this._studentsList.length;
      const increment: number =
        diff > 0 && diff <= this.offSetPerPage
          ? this._studentsListFull.length
          : this._studentsList.length + this.offSetPerPage;
      this._studentsList = this._studentsListFull.slice(0, increment);

      this.nextPageEnable =
        this._studentsListFull.length > 0 &&
        this._studentsListFull.length > this._studentsList.length;
    }
  }

  previousPage() {
    if (!this.verticalPagination) {
      if (this.pointerPage > 0) this.pointerPage--;
      let decrement: number =
        this.pointerPage * this.offSetPerPage + this.offSetPerPage;
      decrement = decrement > 0 ? decrement : this.offSetPerPage;
      let newPointer = this.pointerPage * this.offSetPerPage;
      this._studentsList = this._studentsListFull.slice(
        newPointer > 0 ? newPointer : 0,
        decrement
      );
      if (decrement == this.offSetPerPage) this.previousPageEnable = false;
      this.nextPageEnable = true;
    } else {
      const diff = this._studentsList.length - this.offSetPerPage;
      const decrement: number =
        diff > this.offSetPerPage
          ? this._studentsList.length - this.offSetPerPage
          : this.offSetPerPage;
      this._studentsList = this._studentsListFull.slice(0, decrement);
    }
  }

  unCheckAll() {
    this.checkedStudentsList = [];
  }

  onSelectCard(event:Student & {selectedCard:boolean}){
    if(event.selectedCard ){
      if(!this._selectedStudents.some(e => e.id === event.id))
        this._selectedStudents.push(event);
    }else{
      this._selectedStudents = this._selectedStudents.filter(e => e.id !== event.id);
    }

    this.selectedStudents.emit(this._selectedStudents);
  }

  onSelectAllStudents(){
    this.selectAllStudents = !this.selectAllStudents;

    if(this.selectAllStudents){
      this._selectedStudents = this._studentsList;
    }else{
      this._selectedStudents = [];
    }

    this.selectedStudents.emit(this._selectedStudents);
  }
}
